<template>
	<div class="qn_buttons">
	    <a class="qnbutton btn"
	    :title="set.completed ? 'Completed' : 'Not Completed'" 
	    v-for="set of getEvals"
	    @click="updateCounter(set.id)"
	    :class="[set.completed ? 'btn-success' : 'btn-danger', set.current ? 'thisset active' : '']"
	    >
	      {{ set.id }}
	    </a>
  	</div>
</template>

<script>
	import { mapGetters,mapActions } from 'vuex'

	export default {
		data: function (){
        	return {
        		
        	}
        },
        methods: {
        	...mapActions([
                'updateCounter'
            ])
        },
        computed: {
        	...mapGetters([
	        	'getEvals'
	      	])
        }
	}
</script>