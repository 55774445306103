<template>
<div class="modal-mask">
  <div class="modal-wrapper">
    <div class="modal-container">

      <div class="modal-header">
        <slot name="header">
          Add Comments
        </slot>
      </div>

      <div class="modal-body">
        <slot name="body">
      		<label>Strengths:</label>
        	<textarea rows="4" cols="80" class="form-control" placeholder="Type in the Strengths" id="" v-model="strengths" name="strengths"></textarea>

        	<label>Next Step:</label>
        	<textarea rows="4" cols="80" class="form-control" placeholder="Type in the Next Steps" id="" v-model="next_step" name="next_step"></textarea>
        </slot>
      </div>

      <div class="modal-footer">
        <slot name="footer">
          <!-- <div class="row"> -->
          	<div class="col-md-9"></div>
          	<div class="col-md-3">
          		<button type="button" class="btn btn-primary form-control btn-block" @click="$emit('close')">Done</button>
          	</div>
          <!-- </div> -->
        </slot>
      </div>
    </div>
  </div>
</div>
</template>

<script>
	export default {
		data: function (){
        	return {
        		strengths: '',
        		next_step: ''
        	}
        }
	}
</script>