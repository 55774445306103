import Vue from 'vue'
import Vuex from 'vuex'

// import evaluations from "./modules/evaluations"
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		counter: 1,
		eval: [],
		saveInProgress: false
	},
	getters: {
		getCounter: state => {
			return state.counter
		},
		getCompleteStatus: (state) => (id) => {
	    	const index = state.eval.findIndex(b => b.id === id)
	    	return state.eval[index].completed

	  	},
	  	getEvals: state => {
	  		return state.eval
	  	},
	  	getSubmitStatus: state => {
	  		return state.eval.filter(b => b.completed === false)
	  	},
	  	getSaveProgress: state => {
	  		return state.saveInProgress
	  	}
	},
	mutations: {
		push (state,set) {
			state.eval.push(set)
		},
		toggleCurrent (state,id) {
			const index = state.eval.findIndex(b => b.id === id)
			if(index >= 0)
      			state.eval[index].current = !state.eval[index].current
		},
		changeCounter (state,payload) {
			state.counter = payload
		},
		changeCompleted (state,id) {
			const index = state.eval.findIndex(b => b.id === id)
			if(index >= 0)
      			state.eval[index].completed = !state.eval[index].completed
		},
		toggleSaveProgress(state, payload) {
			state.saveInProgress = payload
		}
	},
	actions: {
		updateCounter(context,payload) {
			context.commit('toggleCurrent',context.getters.getCounter)
			if(context.getters.getCounter !== payload)
				window.scrollTo(0,0);
			context.commit('changeCounter',payload)
			context.commit('toggleCurrent',context.getters.getCounter)
		},
		async saveProgress(context,payload) {
			const formdata = new FormData(payload)
			try {
				context.commit('toggleSaveProgress', true)
				await Vue.axios.post('/saveprogress', formdata);
			} catch (err) {
				//error
			} finally {
				context.commit('toggleSaveProgress', false)
				window.onbeforeunload = null;
				window.location.href = "/evaluations";
			}
		}
	}
})