<template>
    <div>
        <h2>Area {{ set[0].order }}</h2>
        <question 
         v-for="(question,idy) of set" 
         :key="'Ques-'+question.order+question.sub_order" 
         :question="question"
         @markchange="markchange"
         @descchange="descchange"
         :idy="idy">
        </question>
        <modal v-if="size == parseInt(idx)" v-show="showModal" @close="showModal = false"></modal>
        <div class="row">
	        <div class="col-md-3">
	        	<button class="btn btn-primary form-control btn-block" type="button" @click="updateCounter(parseInt(idx) - 1)" v-if="parseInt(idx) !== 1">Previous</button>
	        </div>
	        <div class="col-md-6"><p class="para-cent">Area {{ set[0].order }} Mark: {{ this.mark }} out of {{ set[0].set_max }} | Status: {{ this.status }}</p></div>
	        <div class="col-md-3">
	          <button class="btn btn-primary form-control btn-block" type="button" @click="updateCounter(parseInt(idx) + 1)" v-if="size !== parseInt(idx)">Next</button>
              <span v-else>
                <button class="btn btn-primary form-control btn-block" type="button" id="show-modal" @click="showModal = true">Add Comments</button><hr>
                <button class="btn btn-primary form-control btn-block" @click="submitForm" type="button">Submit</button>
              </span>
	        </div>
        </div>
    </div>
</template>

<script>
    import { mapActions,mapMutations,mapGetters } from 'vuex'

    export default {
        mounted() {
            // this.toggleCurrent(this.set[0].order)
        	for (var i = 0; i < this.set.length; i++) {
        		this.childMarks[i] = 0
                this.childDesc[i] = ""
        	}
        },
        props: {
            set: Array,
            size: Number,
            idx: String,
            eval_type: String,
            refs: Object
        },
        data: function (){
        	return {
        		mark: 0,
        		childMarks: Array(),
                childDesc: Array(),
                showModal: false
        	}
        },
        methods: {
        	markchange: function (event,idc) {
        		this.childMarks[idc] = event
        		this.mark = parseFloat(parseFloat(this.childMarks.reduce(function(a, b) { return a + b; }, 0)).toFixed(1));
                this.$emit('totalmarkchange',this.mark,this.idx)
                this.checkchange()
        	},
            descchange: function (event,idc) {
                this.childDesc[idc] = event
                this.checkchange()
            },
            checkchange: function () {
                // console.log('hit')
                let status = true
                for (let i = 0; i < this.childMarks.length; ++i) {
                    // if(this.childMarks[i] == 0)
                    //     status = false
                    if(this.childDesc[i] == '')
                        status = false
                }
                if(this.getCompleteStatus(this.set[0].order) != status)
                    this.changeCompleted(this.set[0].order)

            },
            submitForm: function () {
                if(!this.refs.form.checkValidity()) {
                    this.refs.form.querySelector(':invalid').reportValidity()
                    return
                }
                if(this.getSubmitStatus.length > 0) {
                    this.$swal({
                        title: 'Area '+this.getSubmitStatus[0].id+' is not complete !!',
                        position: 'top-end',
                        icon: 'error',
                        showConfirmButton: false,
                        timer: 1500,
                        width: 500
                    })
                    this.updateCounter(this.getSubmitStatus[0].id)
                    return false
                }
                
                window.onbeforeunload = null;
                this.refs.form.submit()
            },
            ...mapActions([
                'updateCounter'
            ]),
            ...mapMutations([
                'changeCompleted'
            ])
        },
        computed: {
            status: function() {
                switch(true) {
                    case (this.mark <= (this.set[0].set_max * 0.3)):
                        return 'Very Weak'
                        break;
                    case (this.mark <= (this.set[0].set_max * 0.5)):
                        return 'Weak'
                        break;
                    case (this.mark <= (this.set[0].set_max * 0.7)):
                        return 'Acceptable'
                        break;
                    case (this.mark <= (this.set[0].set_max * 0.8)):
                        return 'Good'
                        break;
                    case (this.mark <= (this.set[0].set_max * 0.9)):
                        return 'Very Good'
                        break;
                    case (this.mark <= (this.set[0].set_max)):
                        return 'Outstanding'
                        break;
                    default:
                    // code block
                }
            },
            ...mapGetters([
                'getCompleteStatus',
                'getSubmitStatus'
            ])
        }
    }
</script>
