<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label><strong>{{ question.order + '. '}}<span v-if="question.sub_order">{{ question.sub_order + '. ' }}</span></strong> {{question.question}}</label>
                    <textarea @input="$emit('descchange',$event.target.value,idy)" rows="4" cols="80" class="form-control" placeholder="Type in your Description" :id="'qd'+question.id" v-model="description" :name="'description['+question.id+']'"></textarea>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
            <div class="form-group">
                <label>Slide to Choose Your Mark</label>
                <input type="range" @input="$emit('markchange',parseFloat($event.target.value),idy)" v-model="mark" :name="'mark['+question.id+']'" :id="'mr'+question.id" min="0" :max="question.max_mark" step="0.1" class="form-control px-0" placeholder="">
            </div>
            </div>
            <div class="col-md-3">
             <p class="para-cent para-mar" id="q1p">Mark: {{ this.mark+' out of '+question.max_mark }}</p>
            </div>
            <!-- <div class="col-md-3">
             <p id="q1e">{{ this.status }}</p>
            </div> -->
        </div>
        <hr>
    </div>
</template>

<script>
    export default {
        async mounted() {
            await this.$nextTick()
            
            if(this.question.answers.length > 0) {
                this.description = this.question.answers[0].description
                this.$emit('descchange',this.description,this.idy)
            
                this.mark = this.question.answers[0].mark
                this.$emit('markchange',parseFloat(this.mark),this.idy)
            }
        },
        props: {
            question: Object,
            idy: Number
        },
        data: function () {
            return {
                mark: 0,
                description: ''
            }
        }
    }
</script>
