<template>
	<div class="col-md-4">
      <div class="form-group">
        <label>{{ label }}</label>
        <v-select 
        class="style-chooser"
        :options="list"
        v-model="selected"></v-select>
        <input type="hidden" :name="name" :value="selected" required>
      </div>
    </div>
</template>

<script>
	export default {
		props: {
			label: String,
			name: String,
            list: Array
        },
		data: function (){
        	return {
        		selected: ''
        	}
        }
	}
</script>