<template>
    <div>
         <question-set v-for="(set,idx) of qsets" 
         :key="'Set-'+idx" 
         :size="Object.keys(qsets).length" 
         :idx="idx" 
         :set="set" 
         v-show="getCounter == idx" 
         @totalmarkchange="totalmarkchange"
         :eval_type="eval_type"
         :refs="refs">
         </question-set>
         <hr>
         <div class="row">
            <div class="col-md-4">
                <p class="para-cent" style="text-align: left;">Overall Mark: {{ this.mark }} out of {{ this.total }}</p>
            </div>
            <div class="col-md-4">
                <button class="btn btn-success form-control btn-block" type="button"
                :disabled="getSaveProgress" @click="saveProgress(refs.form)">
                {{ this.getSaveProgress ? 'Saving in Progress..' : 'Save Draft' }}
                </button>
            </div>
            <div class="col-md-4">
                <p class="para-cent" style="text-align: right;">Overall Status: {{ this.status }}</p>
            </div>
         </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters } from 'vuex'

    export default {
        mounted() {
            for (var key of Object.keys(this.qsets)) {
                this.total += this.qsets[key][0]['set_max']
                this.setMarks[key] = 0
                let curr = (key == 1) ? true : false
                // let ques  = [];
                // this.qsets[key].forEach(q => {
                //     ques.push({'mark':0,description: ''})
                // })
                this.$store.commit('push',{id: this.qsets[key][0]['order'], current: curr,completed: false})
            }
        },
        props: {
            qsets: Object,
            eval_type:String,
            refs: Object
        },
        data: function () {
            return {
                mark: 0,
                total: 0,
                setMarks: Object(),
            }
        },
        methods: {
            totalmarkchange: function(event,idc) {
                this.setMarks[idc] = event
                this.mark = parseFloat(parseFloat(Object.values(this.setMarks).reduce(function(a, b) { return a + b; }, 0)).toFixed(1));
            },
            ...mapActions([
                'saveProgress'
            ])
        },
        computed: {
            status: function() {
                switch(true) {
                    case (this.mark <= (this.total * 0.3)):
                        return 'Very Weak'
                        break;
                    case (this.mark <= (this.total * 0.5)):
                        return 'Weak'
                        break;
                    case (this.mark <= (this.total * 0.7)):
                        return 'Acceptable'
                        break;
                    case (this.mark <= (this.total * 0.8)):
                        return 'Good'
                        break;
                    case (this.mark <= (this.total * 0.9)):
                        return 'Very Good'
                        break;
                    case (this.mark <= (this.total)):
                        return 'Outstanding'
                        break;
                    default:
                    // code block
                }
            },
            ...mapGetters([
                'getCounter',
                'getSaveProgress'
            ])
        }
    }
</script>
