<template>
	<div class="col-md-4">
      <div class="form-group">
        <label>Teacher</label>
        <v-select 
        class="style-chooser"
        :options="teachers" 
        label="name" 
        v-model="selected"
        :reduce="name => name.id"></v-select>
        <input type="hidden" name="teacher" :value="selected" required>
      </div>
    </div>
</template>

<script>
	export default {
    props: {
            teachers: Array
        },
		data: function (){
        	return {
        		selected: ''
        	}
        }
	}
</script>